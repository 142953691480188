
  
  .add-recipe-window {
    width: 100rem;
    z-index: 1000;
    background-color: #fff;
    border-radius: 9px;
    padding: 5rem 6rem;
    transition: all .5s;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 4rem 6rem #00000040;
  }
  
  .add-recipe-window .btn--close-modal {
    color: inherit;
    cursor: pointer;
    background: none;
    border: none;
    font-family: inherit;
    font-size: 3.5rem;
    position: absolute;
    top: .5rem;
    right: 1.6rem;
  }
  
  .overlay {
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: 100;
    background-color: #0006;
    transition: all .5s;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  .upload {
    grid-template-columns: 1fr 1fr;
    gap: 4rem 6rem;
    display: grid;
  }
  
  .upload__column {
    grid-template-columns: 1fr 2.8fr;
    align-items: center;
    gap: 1.5rem;
    display: grid;
  }
  
  .upload__column label {
    color: inherit;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .upload__column input {
    border: 1px solid #ddd;
    border-radius: .5rem;
    padding: .8rem 1rem;
    font-size: 1.5rem;
    transition: all .2s;
  }
  
  .upload__column input::placeholder {
    color: #d3c7c3;
  }
  
  .upload__column input:focus {
    background-color: #f9f5f3;
    border: 1px solid #000;
    outline: none;
  }
  
  .upload__column button {
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 1rem;
  }
  
  .upload__heading {
    text-transform: uppercase;
    grid-column: 1 / -1;
    margin-bottom: 1rem;
    font-size: 2.25rem;
    font-weight: 700;
  }
  
  .upload__btn {
    grid-column: 1 / -1;
    justify-self: center;
  }
  
  /*# sourceMappingURL=index.f7626f62.css.map */
  